/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const VALIDATION_INPUT_TYPE_TEXT = {
    alpha: 'alpha',
    alphaSpace: 'alphaSpace',
    alphaNumeric: 'alphaNumeric',
    alphaDash: 'alphaDash',
    url: 'url'
};

export const VALIDATION_INPUT_TYPE_NUMBER = {
    numeric: 'numeric',
    numericDash: 'numericDash',
    integer: 'integer',
    decimal: 'decimal',
    natural: 'natural',
    naturalNoZero: 'naturalNoZero'
};

export const VALIDATION_INPUT_TYPE_INPUT = {
    email: 'email',
    password: 'password',
    date: 'date',
    phone: 'phone',
    emailList: 'emailList',
    mobile: 'mobile',
    address: 'address'
    // setpassword: 'setpassword'
};

export const VALIDATION_INPUT_TYPE = {
    ...VALIDATION_INPUT_TYPE_TEXT,
    ...VALIDATION_INPUT_TYPE_NUMBER,
    ...VALIDATION_INPUT_TYPE_INPUT
};

export const VALIDATION_MESSAGES = {
    //#region VALIDATION RULE MSG
    isRequired: __('Please fill out this field!'),
    match: __('Incorrect input!'),
    range: __('Value is out of range!'), // Range values are also in Validator.js as they require args
    group: __('Field contains issues!'),
    fileExtension: __('Incorrect File extension upload!'),
    fileSize: __('File size should not exceed %s MB!'),
    maxWords: __('File size should not exceed %s MB!'),
    //#endregion

    //#region VALIDATION RULE MSG
    [VALIDATION_INPUT_TYPE.alpha]: __('Incorrect input! Only letters allowed!'),
    [VALIDATION_INPUT_TYPE.alphaSpace]: __('Incorrect input! Only words allowed!'),
    [VALIDATION_INPUT_TYPE.alphaNumeric]: __('Incorrect input! Alpha-Numeric value required!'),
    [VALIDATION_INPUT_TYPE.alphaDash]: __('Incorrect input! Alpha-Dash value required!'),
    [VALIDATION_INPUT_TYPE.url]: __('Incorrect input! URL required!'),
    [VALIDATION_INPUT_TYPE.numeric]: __('Incorrect input! Numeric value required!'),
    [VALIDATION_INPUT_TYPE.numericDash]: __('Incorrect input! Numeric-Dash value required!'),
    [VALIDATION_INPUT_TYPE.integer]: __('Incorrect input! Integer required!'),
    [VALIDATION_INPUT_TYPE.natural]: __('Incorrect input! Natural number required!'),
    [VALIDATION_INPUT_TYPE.naturalNoZero]: __('Incorrect input!'),
    [VALIDATION_INPUT_TYPE.email]: __('Please enter valid Email id'),
    [VALIDATION_INPUT_TYPE.emailList]: __('Incorrect list of emails!'),
    [VALIDATION_INPUT_TYPE.date]: __('Incorrect date input!'),
    // [VALIDATION_INPUT_TYPE.signInpassword]: __('Please enter password'),
    [VALIDATION_INPUT_TYPE.password]:
        <p block="Password" elem="errorMessage">
            Password must have:
            <ul>
                <li>At least 8 to 15 characters</li>
                <li>1 uppercase & 1 lowercase letter</li>
                <li>1 numeric</li>
                <li>1 special character 1 of these special characters @$!%*?&</li>
            </ul>
            <p block="Password" elem="errorMessage">Password should not contain your email address</p>
        </p>,
    [VALIDATION_INPUT_TYPE.phone]: __('Incorrect phone input!'),
    [VALIDATION_INPUT_TYPE.mobile]: __('Incorrect mobile input!'),
    [VALIDATION_INPUT_TYPE.address]: __('Incorrect input! Only 50 characters allowed!')
    // [VALIDATION_INPUT_TYPE.setpassword]: __('enter correct password input!')
    //#endregion
};

export const VALIDATION_RULES = {
    // Text
    [VALIDATION_INPUT_TYPE.alpha]: /^[a-z]+$/i,
    [VALIDATION_INPUT_TYPE.alphaSpace]: /^[a-z\s]+$/i,
    [VALIDATION_INPUT_TYPE.alphaNumeric]: /^[a-z0-9]+$/i,
    [VALIDATION_INPUT_TYPE.alphaDash]: /^[a-z0-9_\\-]+$/i,
    [VALIDATION_INPUT_TYPE.url]: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?$/,

    // Numbers
    [VALIDATION_INPUT_TYPE.numeric]: /^[0-9]+$/,
    [VALIDATION_INPUT_TYPE.numericDash]: /^[\d\-\s]+$/,
    [VALIDATION_INPUT_TYPE.integer]: /^\\-?[0-9]+$/,
    [VALIDATION_INPUT_TYPE.decimal]: /^\\-?[0-9]*\.?[0-9]+$/,
    [VALIDATION_INPUT_TYPE.natural]: /^[0-9]+$/i,
    [VALIDATION_INPUT_TYPE.naturalNoZero]: /^[1-9][0-9]*$/i,

    // Inputs
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.email]:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
    [VALIDATION_INPUT_TYPE.date]: /\d{4}-\d{1,2}-\d{1,2}/,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im,
    [VALIDATION_INPUT_TYPE.mobile]: /^[0-9]+$/,
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.emailList]:
        /^(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+([,\s]+(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
    [VALIDATION_INPUT_TYPE.password]: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,15}$/,
    [VALIDATION_INPUT_TYPE.address]: /^(.{1,50})$/,

    fileExtension: (value, extensions) => {
        if (!value) {
            return true;
        }
        const acceptedExtensions = extensions.split(', ');
        const fileExtension = value.split('.').pop().toLowerCase();
        return acceptedExtensions.includes(fileExtension);
    },
    fileSize: (value, maxSizeMB) => {
        if (!value) {
            return true;
        }
        const fileSizeBytes = value.size;
        const maxSizeBytes = maxSizeMB * 1024 * 1024;
        return fileSizeBytes <= maxSizeBytes;
    },

    maxWords: (value, maxWords) => {
        if (!value) {
            return true;
        }
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount <= maxWords;
    }
};
